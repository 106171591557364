import axios from 'axios';
import querystring from 'querystring';
import { toast } from 'react-toastify';
import { logoutRequest } from '~/store/modules/auth/actions';

import { store } from '../store';

const urlReview = 'https://apielleve-hom.azurewebsites.net/api/';
const urlProd = 'https://apielleve.azurewebsites.net/api/';

export const ElleveAPI = () => {
  const { elleveToken } = store.getState().auth;
  const baseApi = axios.create({
    baseURL: urlProd,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${elleveToken}`,
    },
  });

  const UNAUTHORIZED = 401;

  baseApi.interceptors.response.use(
    (response) => response,
    (error) => {
      // console.log(error);
      const { status } = error.response;
      if (status === UNAUTHORIZED) {
        // dispatch(userSignOut());
        // console.log('Token has expired');
        toast.error('Sua sessão expirou, faça login novamente ');

        store.dispatch(logoutRequest());
      }
      return Promise.reject(error);
    }
  );

  return baseApi;
};

export default ElleveAPI;
