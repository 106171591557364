import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import estados from '~/config/estados_cidades.json';

let time = null;

const ClearFieldData = (field) => {
  field.value = '';
};
const SetFieldData = (field, value) => {
  field.value = value;
};

export const PreencherCidades = (estadoInput) => {
  if(!estadoInput) return;
  

  const cidadesLista = estados.estados
    .filter(function (estado) {
      return estado.sigla === estadoInput;
    })[0]
    .cidades.map((cidade) => {
      const option = {};
      option.id = cidade;
      option.label = cidade;
      return option;
    });

  return cidadesLista;
};
export const PreencherEndereco = (formRef, inputField, TargetScope) => {
  clearTimeout(time);

  time = setTimeout(() => {
    const cepValue = formRef.current
      .getFieldValue(`${inputField}`)
      .replace(/\.|\-/g, '');
    console.log(cepValue.length);

    const Logradouro = formRef.current.getFieldRef(`${TargetScope}.Logradouro`);
    const Bairro = formRef.current.getFieldRef(`${TargetScope}.Bairro`);
    const Uf = formRef.current.getFieldRef(`${TargetScope}.Uf`);
    const Cidade = formRef.current.getFieldRef(`${TargetScope}.Cidade`);

    if (cepValue.length < 8) {
      // alert('CEP inválido');
      toast.warning('CEP inválido');
      ClearFieldData(Logradouro);
      ClearFieldData(Bairro);
      ClearFieldData(Uf);
      ClearFieldData(Cidade);

      Array.from(Cidade.options).forEach((option) => {
        Cidade.removeChild(option)
      })

      return;
    }

    axios
      .get(`https://viacep.com.br/ws/${cepValue}/json/`)
      .then((response) => {
        // console.log(response);

        if (response.data.erro) {
          toast.warning('CEP inválido');
          ClearFieldData(Logradouro);
          ClearFieldData(Bairro);
          ClearFieldData(Uf);
          ClearFieldData(Cidade);


          Array.from(Cidade.options).forEach((option) => {
            Cidade.removeChild(option)
          })

          return;
        }

        SetFieldData(Logradouro, response.data.logradouro);
        SetFieldData(Bairro, response.data.bairro);
        SetFieldData(Uf, response.data.uf);
        SetFieldData(Logradouro, response.data.logradouro);
        estados.estados
          .filter(function (estado) {
            return estado.sigla === response.data.uf;
          })[0]
          .cidades.map((cidade) => {
            const option = document.createElement('option');
            option.text = cidade;
            option.value = cidade;
            Cidade.appendChild(option);
          });

        SetFieldData(Cidade, response.data.localidade);

        formRef.current.getFieldRef(`${TargetScope}.Numero`).focus();
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
      });
  }, 1000);
};


export const PreencherEnderecoNew = (formRef, inputField, TargetScope) => {
  clearTimeout(time);
  // console.log(formRef.current.getData())

  time = setTimeout(() => {
    const cepValue = formRef.current
      .getFieldValue(`${inputField}`)
      .replace(/\.|\-/g, '');
    // console.log(cepValue);

    const Logradouro = formRef.current.getFieldRef(`${TargetScope}.logradouro`);
    const Bairro = formRef.current.getFieldRef(`${TargetScope}.bairro`);
    const Uf = formRef.current.getFieldRef(`${TargetScope}.uf`);
    const Cidade = formRef.current.getFieldRef(`${TargetScope}.cidade`);

    if (cepValue.length < 8) {
      // alert('CEP inválido');
      toast.warning('CEP inválido');
      ClearFieldData(Logradouro);
      ClearFieldData(Bairro);
      ClearFieldData(Uf);
      ClearFieldData(Cidade);
      Array.from(Cidade.options).forEach((option) => {
        Cidade.removeChild(option)
      })
      return;
    }

    axios
      .get(`https://viacep.com.br/ws/${cepValue}/json/`)
      .then((response) => {
        // console.log(response);

        if (response.data.erro) {
          toast.warning('CEP inválido');
          ClearFieldData(Logradouro);
          ClearFieldData(Bairro);
          ClearFieldData(Uf);
          ClearFieldData(Cidade);
          Array.from(Cidade.options).forEach((option) => {
            Cidade.removeChild(option)
          })
          return;
        }

        SetFieldData(Logradouro, response.data.logradouro);
        SetFieldData(Bairro, response.data.bairro);
        SetFieldData(Uf, response.data.uf);
        SetFieldData(Logradouro, response.data.logradouro);
        estados.estados
          .filter(function (estado) {
            return estado.sigla === response.data.uf;
          })[0]
          .cidades.map((cidade) => {
            const option = document.createElement('option');
            option.text = cidade;
            option.value = cidade;
            Cidade.appendChild(option);
          });

        SetFieldData(Cidade, response.data.localidade);

        formRef.current.getFieldRef(`${TargetScope}.numero`).focus();
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
      });
  }, 1000);
};

export default PreencherEndereco;


export const handleChangeEstado = (selectedEstadoSigla, formRef, TargetScope ) => {


  const CidadeField = formRef.current.getFieldRef(`${TargetScope}.cidade`);
  // console.log("Field",CidadeField)

  // console.log(estados);
  const listaCidades = Object.values(estados.estados).filter((estado) => estado.sigla === selectedEstadoSigla);


  if(listaCidades.length > 0){

    // console.log(CidadeField.options);

    Array.from(CidadeField.options).forEach((option) => {
      CidadeField.removeChild(option)
    })


 
    listaCidades[0].cidades.map((cidade) => {
      var opt = document.createElement('option');
      opt.value = cidade;
      opt.innerHTML = cidade;
      CidadeField.appendChild(opt)
    })
  }else{
    toast.warning('Selecione um estado');

  }
  
  // console.log("listaCidades",listaCidades)
  // console.log("listaCidades",listaCidades[0].cidades)


}